import { itemSelectors } from '../constants/archive-list-widget';

// todo - waiting for back end
const archiveListWidgetController = ({ $w }) => ({
  pageReady: () => {
    $w(itemSelectors.MEDIA_BOX).show();
  },
});

export default archiveListWidgetController;
